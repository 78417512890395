var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardHeader", [
                    _vm._v(" Card title "),
                    _c("div", { staticClass: "card-header-actions" }, [
                      _c(
                        "a",
                        {
                          staticClass: "card-header-action",
                          attrs: {
                            href:
                              "https://coreui.io/vue/docs/components/card-components",
                            rel: "noreferrer noopener",
                            target: "_blank"
                          }
                        },
                        [
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v("docs")
                          ])
                        ]
                      )
                    ])
                  ]),
                  _c("CCardBody", [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                [
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))]),
                  _c("CCardFooter", [_vm._v("Card Footer")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _c("CIcon", { attrs: { name: "cil-check" } }),
                      _vm._v(" Card with icon")
                    ],
                    1
                  ),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _vm._v(" Card with switch "),
                      _c("CSwitch", {
                        staticClass: "float-right",
                        attrs: {
                          size: "sm",
                          shape: "pill",
                          color: "info",
                          "data-on": "On",
                          "data-off": "Off",
                          checked: true
                        }
                      })
                    ],
                    1
                  ),
                  _c("CCardBody", [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _vm._v(" Card with label "),
                      _c(
                        "CBadge",
                        {
                          staticClass: "float-right",
                          attrs: { color: "success" }
                        },
                        [_vm._v("Success")]
                      )
                    ],
                    1
                  ),
                  _c("CCardBody", [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                [
                  _c(
                    "CCardHeader",
                    [
                      _vm._v(" Card with label "),
                      _c(
                        "CBadge",
                        {
                          staticClass: "float-right",
                          attrs: { shape: "pill", color: "danger" }
                        },
                        [_vm._v("42")]
                      )
                    ],
                    1
                  ),
                  _c("CCardBody", [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "border-color": "primary" } },
                [
                  _c("CCardHeader", [_vm._v("Card outline primary")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "border-color": "secondary" } },
                [
                  _c("CCardHeader", [_vm._v("Card outline secondary")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "border-color": "success" } },
                [
                  _c("CCardHeader", [_vm._v("Card outline success")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "border-color": "info" } },
                [
                  _c("CCardHeader", [_vm._v("Card outline info")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "border-color": "warning" } },
                [
                  _c("CCardHeader", [_vm._v("Card outline warning")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "border-color": "danger" } },
                [
                  _c("CCardHeader", [_vm._v("Card outline danger")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "accent-color": "primary" } },
                [
                  _c("CCardHeader", [_vm._v("Card with primary accent")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "accent-color": "secondary" } },
                [
                  _c("CCardHeader", [_vm._v("Card with secondary accent")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "accent-color": "success" } },
                [
                  _c("CCardHeader", [_vm._v("Card with success accent")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "accent-color": "info" } },
                [
                  _c("CCardHeader", [_vm._v("Card with info accent")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "accent-color": "info" } },
                [
                  _c("CCardHeader", [_vm._v("Card with info accent")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                { attrs: { "accent-color": "danger" } },
                [
                  _c("CCardHeader", [_vm._v("Card with danger accent")]),
                  _c("CCardBody", [_vm._v(_vm._s(_vm.loremIpsum))])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  staticClass: "text-center",
                  attrs: {
                    color: "gradient-primary",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [
                  _c("blockquote", { staticClass: "card-blockquote" }, [
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                      )
                    ]),
                    _c("footer", [
                      _vm._v("Someone famous in "),
                      _c("cite", { attrs: { title: "Source Title" } }, [
                        _vm._v("Source Title")
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  staticClass: "text-center",
                  attrs: {
                    color: "gradient-success",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [
                  _c("blockquote", { staticClass: "card-blockquote" }, [
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                      )
                    ]),
                    _c("footer", [
                      _vm._v("Someone famous in "),
                      _c("cite", { attrs: { title: "Source Title" } }, [
                        _vm._v("Source Title")
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  staticClass: "text-center",
                  attrs: {
                    color: "gradient-info",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [
                  _c("blockquote", { staticClass: "card-blockquote" }, [
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                      )
                    ]),
                    _c("footer", [
                      _vm._v("Someone famous in "),
                      _c("cite", { attrs: { title: "Source Title" } }, [
                        _vm._v("Source Title")
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  staticClass: "text-center",
                  attrs: {
                    color: "gradient-warning",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [
                  _c("blockquote", { staticClass: "card-blockquote" }, [
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                      )
                    ]),
                    _c("footer", [
                      _vm._v("Someone famous in "),
                      _c("cite", { attrs: { title: "Source Title" } }, [
                        _vm._v("Source Title")
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  staticClass: "text-center",
                  attrs: {
                    color: "gradient-danger",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [
                  _c("blockquote", { staticClass: "card-blockquote" }, [
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                      )
                    ]),
                    _c("footer", [
                      _vm._v("Someone famous in "),
                      _c("cite", { attrs: { title: "Source Title" } }, [
                        _vm._v("Source Title")
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  staticClass: "text-center",
                  attrs: { color: "gradient-secondary", "body-wrapper": "" }
                },
                [
                  _c("blockquote", { staticClass: "card-blockquote" }, [
                    _c("p", [
                      _vm._v(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante."
                      )
                    ]),
                    _c("footer", [
                      _vm._v("Someone famous in "),
                      _c("cite", { attrs: { title: "Source Title" } }, [
                        _vm._v("Source Title")
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  attrs: {
                    color: "gradient-primary",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  attrs: {
                    color: "gradient-success",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  attrs: {
                    color: "gradient-info",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  attrs: {
                    color: "gradient-warning",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "CCard",
                {
                  attrs: {
                    color: "gradient-danger",
                    "body-wrapper": "",
                    "text-color": "white"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.loremIpsum) + " ")]
              )
            ],
            1
          ),
          _c(
            "CCol",
            { attrs: { sm: "6", md: "4" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _vm.show
                    ? _c(
                        "CCard",
                        { attrs: { color: "gradient-secondary" } },
                        [
                          _c("CCardHeader", [
                            _vm._v(" Card with header actions "),
                            _c(
                              "div",
                              { staticClass: "card-header-actions" },
                              [
                                _c(
                                  "CLink",
                                  {
                                    staticClass:
                                      "card-header-action btn-setting",
                                    attrs: { href: "#" }
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-settings" }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "CLink",
                                  {
                                    staticClass:
                                      "card-header-action btn-minimize",
                                    on: {
                                      click: function($event) {
                                        _vm.isCollapsed = !_vm.isCollapsed
                                      }
                                    }
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: {
                                        name:
                                          "cil-chevron-" +
                                          (_vm.isCollapsed ? "bottom" : "top")
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "CLink",
                                  {
                                    staticClass: "card-header-action btn-close",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        _vm.show = false
                                      }
                                    }
                                  },
                                  [
                                    _c("CIcon", {
                                      attrs: { name: "cil-x-circle" }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "CCollapse",
                            { attrs: { show: _vm.isCollapsed, duration: 400 } },
                            [
                              _c("CCardBody", [
                                _vm._v(" " + _vm._s(_vm.loremIpsum) + " ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }